import { useState, createContext, useEffect } from "react";
import "./App.css";
import SectionFive from "./components/sectionFive/SectionFive";
import SectionFour from "./components/sectionFour/SectionFour";
import SectionOne from "./components/sectionOne/SectionOne";
import SectionSix from "./components/sectionSix/SectionSix";
import SectionThree from "./components/sectionThree/SectionThree";
import SectionTwo from "./components/sectionTwo/SectionTwo";
import SectionOneMobile from "./components/sectionOneMobile/SectionOneMobile";
import SectionTwoMobile from "./components/sectionTwoMobile/SectionTwoMobile";
import SectionThreeMobile from "./components/sectionThreeMobile/SectionThreeMobile";
import SectionFourMobile from "./components/sectionFourMobile/SectionFourMobile";
import SectionFiveMobile from "./components/sectionFiveMobile/SectionFiveMobile";
import SectionSixMobile from "./components/sectionSixMobile/SectionSixMobile";

const NavContext = createContext();

function App() {
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
   
      switch(index){
        case 0:
       
        var muhammadAqdas = document.getElementById("section-one-video");

        if (muhammadAqdas) {
          muhammadAqdas.style.animationName = "section-one-video-up-ani";
          muhammadAqdas.style.animationDuration = "1.2s";
          muhammadAqdas.style.animationDelay = "0s";
          muhammadAqdas.style.opacity = "1";
        }
      
        setTimeout(function () {
          setIndex(1);
          setTimeout(function () {
            if (muhammadAqdas) {
              muhammadAqdas.style.animationName = ""; // Reset the animation name
              muhammadAqdas.style.opacity = "1";
              const d_video = document.getElementById("desktop-video");
              if (d_video) {
                d_video.pause();
                d_video.currentTime = 0; // Resets current time to 0
              }
            }
          }, 500); // Adjust the delay as needed
        }, 500);
        break;
        case 1:
          var twoAqdas = document.getElementById("section-two-paragraph")
     
          if(twoAqdas){
          twoAqdas.style.animationName = "section-two-para-up-ani"
          twoAqdas.style.animationDuration = "1.2s"
          twoAqdas.style.animationDelay = "0s"
          twoAqdas.style.opacity = "1"
          }
          setTimeout(function() {
            setIndex(2)
            setTimeout(function() {
              if (twoAqdas) {
                twoAqdas.style.animationName = ""; // Reset the animation name
                 twoAqdas.style.opacity = "1"
            }
          }, 500); // Adjust the delay as needed
          }, 500);
        break;
        case 2:
          var threeAqdas = document.getElementById("section-three-image")
     
          if(threeAqdas){
          threeAqdas.style.animationName = "section-three-up-ani"
          threeAqdas.style.animationDuration = "1.2s"
          threeAqdas.style.animationDelay = "0s"
          threeAqdas.style.opacity = "1"
          }
          setTimeout(function() {
            setIndex(3)
            setTimeout(function() {
              if (threeAqdas) {
                threeAqdas.style.animationName = ""; // Reset the animation name
                 threeAqdas.style.opacity = "1"
            }
          }, 500); // Adjust the delay as needed
          }, 500);
        break;
        case 3:
          var fourAqdas = document.getElementById("section-four-paragraph")
       
          if(fourAqdas){
          fourAqdas.style.animationName = "section-four-up-ani"
          fourAqdas.style.animationDuration = "1.2s"
          fourAqdas.style.animationDelay = "0s"
          fourAqdas.style.opacity = "1"
          }
          setTimeout(function() {
            setIndex(4)
            setTimeout(function() {
              if (fourAqdas) {
                fourAqdas.style.animationName = ""; // Reset the animation name
                 fourAqdas.style.opacity = "1"
            }
          }, 500); // Adjust the delay as needed
          }, 500);
        break;
        case 4:
          var fiveAqdas = document.getElementById("section-five-container")
          var fiveAqdasImage = document.getElementById("section-five-image")
       
          if(fiveAqdas){
          fiveAqdas.style.animationName = "section-five-container-up-ani"
          fiveAqdas.style.animationDuration = "1.2s"
          fiveAqdas.style.animationDelay = "0s"
          fiveAqdas.style.opacity = "1"
          }
          if(fiveAqdasImage){
          fiveAqdasImage.style.animationName = "section-five-image-left-ani"
          fiveAqdasImage.style.animationDuration = "1.2s"
          fiveAqdasImage.style.animationDelay = "0s"
          fiveAqdasImage.style.opacity = "1"
          }
          setTimeout(function() {
            setIndex(5)
            setTimeout(function() {
              if (fiveAqdas) {
                fiveAqdas.style.animationName = ""; // Reset the animation name
                 fiveAqdas.style.opacity = "1"
            }
              if (fiveAqdasImage) {
                fiveAqdasImage.style.animationName = ""; // Reset the animation name
                 fiveAqdasImage.style.opacity = "1"
            }
          }, 500); // Adjust the delay as needed
          }, 500);
        break;

        default:
        break;

      }
    } else if (event.key === "ArrowUp") {
      switch(index){
        case 1:
        setIndex(0);
        break;
        case 2:
        setIndex(1);
        break;
        case 3:
        setIndex(2);
        break;
        case 4:
        setIndex(3);
        break;
        case 5:
        setIndex(4);
        break;

        default:
        break;

      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
  
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [index]);


  if (isMobile) {
    return (
      <div>
        <SectionOneMobile/>
        <SectionTwoMobile/>
        <SectionThreeMobile/>
        <SectionFourMobile/>
        <SectionFiveMobile/>
        <SectionSixMobile/>
      </div>
    );
  }

  return (
    <NavContext.Provider value={{index, setIndex}}>
    <div>
      <div className={`${index === 0 ? "d-block" : "d-none"}`}>
        <SectionOne />
      </div>
      <div className={`${index === 1 ? "d-block" : "d-none"}`}>
        <SectionTwo />
      </div>
      <div className={`${index === 2 ? "d-block" : "d-none"}`}>
        <SectionThree />
      </div>
      <div className={`${index === 3 ? "d-block" : "d-none"}`}>
        <SectionFour />
      </div>
      <div className={`${index === 4 ? "d-block" : "d-none"}`}>
        <SectionFive />
      </div>
      <div className={`${index === 5 ? "d-block" : "d-none"}`}>
        <SectionSix />
      </div>
    </div>

    </NavContext.Provider>

  );
}

export default App;
export {NavContext}
