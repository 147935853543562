import React, { useContext, useEffect } from "react";
import "./SectionThree.css";
import { NavContext } from "../../App";
function SectionThree() {
  
  const {index, setIndex} = useContext(NavContext)
  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if(event.deltaY > 0){
        var threeAqdas = document.getElementById("section-three-image")
     
        if(threeAqdas){
        threeAqdas.style.animationName = "section-three-up-ani"
        threeAqdas.style.animationDuration = "1.2s"
        threeAqdas.style.animationDelay = "0s"
        threeAqdas.style.opacity = "1"
        }
        setTimeout(function() {
          setIndex(3)
          setTimeout(function() {
            if (threeAqdas) {
              threeAqdas.style.animationName = ""; // Reset the animation name
               threeAqdas.style.opacity = "1"
          }
        }, 500); // Adjust the delay as needed
        }, 500);
      }else{
        setIndex(1)
      }
    }
  };
  useEffect(() => {
    const sectionThreeElement = document.getElementById("section-three");
    if (sectionThreeElement) {
      sectionThreeElement.addEventListener("wheel", handleWheel);
      
    }

    return () => {
      console.log("removed 3");
      if (sectionThreeElement) {
        sectionThreeElement.removeEventListener("wheel", handleWheel);
     
      }
    };
  }, [index]);
  return (
    <div id="section-three" className="section-three container-fluid d-flex justify-content-center align-items-center">
        <img className="section-two-logo" src="/image/Logo.svg" alt="" />
      <div className="container text-center">
       <img id="section-three-image" className="section-three-image img-fluid" src="/image/section-three-image.svg" alt="" />
      </div>
    </div>
  );
}

export default SectionThree;
