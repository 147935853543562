import React, { useContext, useEffect } from "react";
import "./SectionFour.css";
import { NavContext } from "../../App";
function SectionFour() {
  const {index, setIndex} = useContext(NavContext)
  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if(event.deltaY > 0){
       
          var fourAqdas = document.getElementById("section-four-paragraph")
       
          if(fourAqdas){
          fourAqdas.style.animationName = "section-four-up-ani"
          fourAqdas.style.animationDuration = "1.2s"
          fourAqdas.style.animationDelay = "0s"
          fourAqdas.style.opacity = "1"
          }
          setTimeout(function() {
            setIndex(4)
            setTimeout(function() {
              if (fourAqdas) {
                fourAqdas.style.animationName = ""; // Reset the animation name
                 fourAqdas.style.opacity = "1"
            }
          }, 500); // Adjust the delay as needed
          }, 500);
      }else{
        setIndex(2)
      }
    }
  };
  useEffect(() => {
    const sectionFourElement = document.getElementById("section-four");
    if (sectionFourElement) {
      sectionFourElement.addEventListener("wheel", handleWheel);
      
    }

    return () => {
      console.log("removed 3");
      if (sectionFourElement) {
        sectionFourElement.removeEventListener("wheel", handleWheel);
     
      }
    };
  }, [index]);
  return (
    <div id="section-four" className="section-four container-fluid d-flex justify-content-center align-items-center">
        <img className="section-two-logo" src="/image/Logo.svg" alt="" />
      <div className="container">
        <p id="section-four-paragraph" className="text-center fs-4 section-four-paragraph">
        Whether you're a painter, a sculptor, a woodworker, or any other kind of creator, your story deserves to be heard, your craft deserves to be <span className="bold-text">seen</span>.
        </p>
       
      </div>
    </div>
  );
}

export default SectionFour;
