import React, { useEffect } from "react";
import "./SectionFiveMobile.css";
import { useInViewport } from "react-in-viewport";
function SectionFiveMobile() {

  return (
    <div id="section-five-mobile" className="section-five-mobile container-fluid d-flex justify-content-center align-items-center">

        <img className="section-five-image-mobile" style={{zIndex: "1"}} src="/image/section-five-image.svg" alt="" />
        <div className="container section-five-container-mobile text-center" style={{zIndex: "2"}}>
          <div className="row">
            <div className="col-12">
            <img className="section-five-heading-mobile img-fluid" src="/image/section-five-heading.svg" alt="" />
            </div>
            <div className="col-12 mt-3">
            <a href="#section-six-mobile" className="btn section-five-button-mobile">Submission Form</a>
            </div>
          </div>
        </div>
      
    </div>
  );
}

export default SectionFiveMobile;
