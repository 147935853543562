import React, { useState, useRef, useEffect, useContext } from "react";
import "./SectionSixMobile.css";
import { Lottie } from "lottie-react";
import { NavContext } from "../../App";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function SectionSixMobile() {

  const imageOneRef = useRef(null);
  const imageTwoRef = useRef(null);
  const imageThreeRef = useRef(null);

  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [contact_number, setNumber] = useState("+92");
  const [story, setStory] = useState("");
  const [products, setProducts] = useState("");
  const [facebook_link, setFacebookLink] = useState("");
  const [instagram_link, setInstagramLink] = useState("");
  const [loading, setLoading] = useState(false);
  const onOneImageClick = () => {
    imageOneRef.current.click();
  };
  const onTwoImageClick = () => {
    imageTwoRef.current.click();
  };
  const onThreeImageClick = () => {
    imageThreeRef.current.click();
  };
  const handleImage1Change = (e) => {
    setImage1(e.target.files[0]);
  };
  const handleImage2Change = (e) => {
    setImage2(e.target.files[0]);
  };
  const handleImage3Change = (e) => {
    setImage3(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("full_name", full_name);
    formData.append("email", email);
    formData.append("country", country);
    formData.append("city", city);
    formData.append("contact_no", contact_number);
    formData.append("story", story);
    formData.append("products", products);
    formData.append("facebook_link", facebook_link);
    formData.append("instagram_link", instagram_link);
    formData.append("image1", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);

    try {
      setLoading(true)
      const response = await fetch("https://v2.hype-x.ca:5001/api/auth/form/submit", {
        method: "POST",
        body: formData,
      });

      const json = await response.json();

      if (!response.ok) {
        alert(json.message);
      }
      if (response.ok) {
        alert("Your form is submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setFullName("");
      setEmail("");
      setStory("");
      setProducts("");
      setFacebookLink("");
      setInstagramLink("");
      setImage1("");
      setImage2("");
      setImage3("");
      setLoading(false)
    }
  };
  return (
    <div id="section-six-mobile" className="section-six-mobile">

      <div className="container">
        <div className="row">
          <div className="col-md-6 section-six-form-mobile">
            <form onSubmit={handleSubmit}
              className="row g-3 d-flex justify-content-center needs-validation"
              novalidate
            >
              <div className="col-md-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form-control form-control-custom custom-input"
                  // id="inputEmail4"
                  onChange={(e) => setFullName(e.target.value)}
                  value={full_name}
                  required
                  id="validationDefault01"
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Country"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  required
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="City"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  required
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center phone-column">
                <PhoneInput country={'pk'} className="phone-input" value={contact_number} onChange={(e)=>setNumber(e)} />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Your Story"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setStory(e.target.value)}
                  value={story}
                  required
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Product"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setProducts(e.target.value)}
                  value={products}
                  required
                />
              </div>
              <div className="col-12">
                <div className="container-fluid" style={{ width: "90%" }}>
                  <div className="image-row">
                    {image1 !== "" ? (
                      <div className="image-input-footer-thumbnail-mobile">
                        <img
                          src={URL.createObjectURL(image1)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "28px",
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        role="button"
                        onClick={onOneImageClick}
                        className="image-input-footer-mobile"
                      >
                        <span style={{ color: "#2b392a" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg>
                        </span>
                        <p className="mt-2 footer-add-image-text-mobile text-center">
                          Add Product Image
                        </p>
                      </div>
                    )}

                    <input
                      type="file"
                      ref={imageOneRef}
                      hidden
                      className="form-control"
                      onChange={handleImage1Change}
                      accept="image/*"
                    />
                    {image2 !== "" ? (
                      <div className="image-input-footer-thumbnail-mobile">
                        <img
                          src={URL.createObjectURL(image2)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "28px",
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        role="button"
                        onClick={onTwoImageClick}
                        className="image-input-footer-mobile"
                      >
                        <span style={{ color: "#2b392a" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg>
                        </span>
                        <p className="mt-2 footer-add-image-text-mobile text-center">
                          Add Product Image
                        </p>
                      </div>
                    )}

                    <input
                      type="file"
                      ref={imageTwoRef}
                      hidden
                      className="form-control"
                      onChange={handleImage2Change}
                      accept="image/*"
                    />
                    {image3 !== "" ? (
                      <div className="image-input-footer-thumbnail-mobile">
                        <img
                          src={URL.createObjectURL(image3)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "28px",
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        role="button"
                        onClick={onThreeImageClick}
                        className="image-input-footer-mobile"
                      >
                        <span style={{ color: "#2b392a" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg>
                        </span>
                        <p className="mt-2 footer-add-image-text-mobile text-center">
                          Add Product Image
                        </p>
                      </div>
                    )}

                    <input
                      type="file"
                      ref={imageThreeRef}
                      hidden
                      className="form-control"
                      onChange={handleImage3Change}
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-center face-icon-col">
                <span className="face-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </span>

                <input
                  type="text"
                  placeholder="Facebook Account link (optional)"
                  className="form-control custom-input"
                  id="inputEmail4"
                  onChange={(e) => setFacebookLink(e.target.value)}
                  style={{paddingLeft: "40px"}}
                  value={facebook_link}
                />
              </div>
              <div className="col-12 text-center d-flex justify-content-center face-icon-col">
                <span className="face-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                  </svg>
                </span>

                <input
                  type="text"
                  placeholder="Instagram Account link (optional)"
                  className="form-control custom-input"
                  style={{paddingLeft: "40px"}}
                  id="inputEmail4"
                  onChange={(e) => setInstagramLink(e.target.value)}
                  value={instagram_link}
                />
              </div>

              <div className="col-12 text-center">
                {loading ? (
                  <button className="btn section-five-button-mobile w-75">
                    Submitting...
                  </button>
                ) : (
                  <button
                  type="submit"
                  
                    className="btn section-five-button-mobile w-75"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center flex-column text-center">
            <iframe
              className="section-six-iframe"
              style={{marginTop: "40px"}}
              src="
https://lottie.host/embed/e3818d18-7760-46d5-918d-bf88bd669f62/i1rDRVcFrm.json"
            />
            <div style={{marginTop: "40px"}} className="social-div-mobile">
              <span className="follow-us-mobile">Follow us on</span>
              <a
                href="https://www.facebook.com/exyst.store"
                target="_blank"
                role="button"
                className="face-ico"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>

              <a
                href="https://www.instagram.com/exyst.store/"
                target="_blank"
                role="button"
                className="face-ico"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@Expressyourstory"
                target="_blank"
                role="button"
                className="face-ico"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-youtube"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSixMobile;
